import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {breakpointsMediaQueries, COLORS} from '../style/my-theme';
import {ExternalLinkButton} from './components';

const StyledCounter = styled.div.attrs({
  className: 'counter'
})`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem 0;
  margin: 4rem 0;
  background-image: url('/images/footer-stickers.jpeg');

  h2 {
    flex-direction: column;
    text-align: center;
    color: ${COLORS.blueLight};

    ${breakpointsMediaQueries.md} {
      font-size: 1.2rem;
    }
  }
  .counter-wrapper {
    display: flex;
    justify-content: center;
    gap: 4rem;
    ${breakpointsMediaQueries.md} {
      gap: initial;
    }
  }

  .counter-item {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;

    span.label {
      font-size: 0.8rem;
      color: ${COLORS.blueLight};
      width: 100%;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      margin-top: 0.1rem;
    }

    span.value {
      padding: 0 0.5rem;
      font-size: 4rem;
      color: ${COLORS.white};
      text-align: center;
    }

    @media (max-width: 500px) {
      span.value {
        font-size: 2.5rem;
      }
    }
  }
`;

export const Counter = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
  }, [timeLeft]);

  return (
    <StyledCounter suppressHydrationWarning>
      <h2>We're ready to launch in:</h2>
      <div className="counter-wrapper">
        <div className="counter-item">
          <span className="value">{String(timeLeft.days).padStart(2, '0')}</span>
          <span className="label">Days</span>
        </div>

        <div className="counter-item">
          <span className="value">{String(timeLeft.hours).padStart(2, '0')}</span>
          <span className="label">Hours</span>
        </div>

        <div className="counter-item">
          <span className="value">{String(timeLeft.minutes).padStart(2, '0')}</span>
          <span className="label">Min</span>
        </div>

        <div className="counter-item">
          <span className="value">{String(timeLeft.seconds).padStart(2, '0')}</span>
          <span className="label">Sec</span>
        </div>
      </div>
      {/* <ExternalLinkButton label="SCHEDULE" url="/armada-schedule-2023-v2.pdf"></ExternalLinkButton> */}
    </StyledCounter>
  );
};

type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const calculateTimeLeft = () => {
  let year = 2024;
  let month = 12;
  let day = 5;

  const difference = +new Date(`${month}/${day}/${year}`) - +new Date();

  let timeLeft: TimeLeft = {} as TimeLeft;
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
};
